var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"bg",style:({ minHeight: _vm.boxheight })},[_c('div',{staticStyle:{"font-size":"18px"}},[_vm._v("綁定支付方式")]),_c('div',{staticClass:"card"},[_c('van-cell',{staticStyle:{"font-weight":"500","text-align":"left"},attrs:{"title":"支付方式"}}),_c('van-radio-group',{on:{"change":_vm.onChange},model:{value:(_vm.radio),callback:function ($$v) {_vm.radio=$$v},expression:"radio"}},[_c('van-cell-group',{attrs:{"border":false}},[_c('van-cell',{attrs:{"title":"","clickable":""},on:{"click":function($event){_vm.radio = '1'}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('van-image',{attrs:{"width":"70px","height":"40px","src":_vm.visaPayIcon}}),_c('van-image',{attrs:{"width":"70px","height":"40px","src":_vm.mastercardPayIcon}})]},proxy:true},{key:"right-icon",fn:function(){return [_c('van-radio',{attrs:{"name":"1"},scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('van-image',{attrs:{"width":"22px","height":"22px","src":props.checked
                                                ? _vm.activeIcon
                                                : _vm.inactiveIcon}})]}}])})]},proxy:true}])}),_c('van-cell',{attrs:{"title":"","clickable":""},on:{"click":function($event){_vm.radio = '2'}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('van-image',{staticStyle:{"margin-left":"10px"},attrs:{"width":"110px","height":"30px","src":_vm.linePayIcon}})]},proxy:true},{key:"right-icon",fn:function(){return [_c('van-radio',{attrs:{"name":"2"},scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('van-image',{attrs:{"width":"22px","height":"22px","src":props.checked
                                                ? _vm.activeIcon
                                                : _vm.inactiveIcon}})]}}])})]},proxy:true}])})],1)],1),_c('van-cell',{staticStyle:{"text-align":"left"},attrs:{"title":"信用卡不支援金融信用卡"}})],1),_c('div',{staticClass:"read-moudle"},[_c('van-checkbox',{on:{"change":_vm.readChange},scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('van-image',{attrs:{"width":"18px","height":"18px","src":props.checked
                                ? _vm.activeClauseIcon
                                : _vm.inactiveClauseIcon}})]}}]),model:{value:(_vm.isCheck),callback:function ($$v) {_vm.isCheck=$$v},expression:"isCheck"}}),_c('span',{staticClass:"clause"},[_vm._v("點擊確認付款，表示已閱讀並同意《用戶協議》")])],1),_c('p',{staticStyle:{"font-size":"12px","text-align":"left","margin-left":"27px"}},[_vm._v(" 綁定完成請耐心等待畫面跳轉，請勿手動操作返回避免重複綁定 ")]),_c('div',{staticClass:"login-way"},[_c('div',{staticClass:"sms-way",on:{"click":_vm.confirm}},[_vm._v("前往綁定")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }