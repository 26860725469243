<!--
 * @Author       : JiangChao
 * @Date         : 2022-12-12 13:31:44
 * @LastEditors  : JiangChao
 * @LastEditTime : 2023-04-22 17:36:00
 * @Description  : 免押支付
-->
<template>
    <div class="container">
        <div class="bg" :style="{ minHeight: boxheight }">
            <!-- <p style="font-size:14px;text-align:left">支付新台幣384元押金，即可立即使用</p> -->
            <!-- <div class="logo"> -->
            <div style="font-size: 18px">綁定支付方式</div>
            <!-- </div> -->
            <div class="card">
                <van-cell
                    title="支付方式"
                    style="font-weight: 500; text-align: left"
                >
                </van-cell>
                <van-radio-group v-model="radio" @change="onChange">
                    <van-cell-group :border="false">
                        <van-cell title="" clickable @click="radio = '1'">
                            <template #icon>
                                <van-image
                                    width="70px"
                                    height="40px"
                                    :src="visaPayIcon"
                                />
                                <van-image
                                    width="70px"
                                    height="40px"
                                    :src="mastercardPayIcon"
                                />
                            </template>
                            <template #right-icon>
                                <van-radio name="1">
                                    <template #icon="props">
                                        <van-image
                                            width="22px"
                                            height="22px"
                                            :src="
                                                props.checked
                                                    ? activeIcon
                                                    : inactiveIcon
                                            "
                                        />
                                    </template>
                                </van-radio>
                            </template>
                        </van-cell>
                        <van-cell title="" clickable @click="radio = '2'">
                            <template #icon>
                                <van-image
                                    width="110px"
                                    height="30px"
                                    style="margin-left: 10px"
                                    :src="linePayIcon"
                                />
                            </template>
                            <template #right-icon>
                                <van-radio name="2">
                                    <template #icon="props">
                                        <van-image
                                            width="22px"
                                            height="22px"
                                            :src="
                                                props.checked
                                                    ? activeIcon
                                                    : inactiveIcon
                                            "
                                        />
                                    </template>
                                </van-radio>
                            </template>
                        </van-cell>
                    </van-cell-group>
                </van-radio-group>
                <van-cell
                    title="信用卡不支援金融信用卡"
                    style="text-align: left"
                >
                </van-cell>
            </div>

            <div class="read-moudle">
                <van-checkbox v-model="isCheck" @change="readChange">
                    <template #icon="props">
                        <van-image
                            width="18px"
                            height="18px"
                            :src="
                                props.checked
                                    ? activeClauseIcon
                                    : inactiveClauseIcon
                            "
                        />
                    </template>
                </van-checkbox>

                <span class="clause"
                    >點擊確認付款，表示已閱讀並同意《用戶協議》</span
                >
            </div>
             <p style="font-size: 12px; text-align: left;margin-left:27px">
                綁定完成請耐心等待畫面跳轉，請勿手動操作返回避免重複綁定
            </p>

            <div class="login-way">
                <div class="sms-way" @click="confirm">前往綁定</div>
            </div>
        </div>
    </div>
</template>

<script>
import { handleErr } from "@/helpers";

export default {
    name: "",
    data() {
        return {
            radio: "",
            activeIcon: require("@/assets/imgs/png/icon_agree_selected_circle.png"),
            inactiveIcon: require("@/assets/imgs/png/icon_login_default_circle.png"),
            iconContentYajin: require("@/assets/imgs/png/icon_content_yajin.png"),
            // applePayIcon: require("@/assets/imgs/png/apple_pay.png"),
            // jkIcon: require("@/assets/imgs/png/img_pay.png"),
            // googlePayIcon: require("@/assets/imgs/png/google_pay.png"),
            linePayIcon: require("@/assets/imgs/png/line_pay.jpg"),
            // pxIcon: require("@/assets/imgs/png/ic_px.jpg"),
            mastercardPayIcon: require("@/assets/imgs/png/mastercard_pay.png"),
            visaPayIcon: require("@/assets/imgs/png/visa_pay.png"),
            // subscriptionIcon: require("@/assets/imgs/png/icon_subscription.png"),
            activeClauseIcon: require("@/assets/imgs/png/icon_agree_selected.png"),
            inactiveClauseIcon: require("@/assets/imgs/png/icon_login_default.png"),
            isCheck: false,
        };
    },
    methods: {
        onChange(val) {
            console.log("val: ", val);
            this.radio = val;
        },
        confirm() {
            if (!this.isCheck) {
                handleErr({
                    app: this.$parent,
                    msg: "請確認已閱讀並同意《用戶協議》！",
                });
                return;
            }
            if (!this.radio) {
                handleErr({ app: this.$parent, msg: "請選擇支付方式！" });
                return;
            }
            switch (this.radio) {
                case "1":
                    this.$_href("BindCreditCard");
                    break;
                case "2":
                    this.bindLightBoxOnpress();
                    break;
            }
        },
        $_href(page) {
            if (!page) return;
            if (page.indexOf("https") !== -1) {
                window.location.href = page;
            } else {
                this.$router.push(page);
            }
        },
        readChange(val) {
            console.log("val: ", val);
            if (val) {
                this.isCheck = true;
            } else {
                this.isCheck = false;
            }
        },
        async bindLightBoxOnpress() {
            try {
                const res = await this.$axios.get("linePay/saveKey2");
                console.log("res: ", res);
                if (res.data.success) {
                    //前端调后端saveKeyConfirm
                    window.location.href = res.data.result;
                } else {
                    throw res;
                }
            } catch (error) {
                handleErr({ ...error, app: this.$parent });
            }
        },
    },
    props: {},
    components: {},
    computed: {
        boxheight() {
            return `${window.innerHeight}px`;
        },
    },
    mounted() {
        // const token = localStorage.getItem("token");
        const token = window.$cookies.get("token");
        if (token) {
            this.$axios.defaults.headers.common["token"] = token;
            this.$axios.defaults.headers.common["appType"] = "PHONE";
        }
    },
    created() {},
};
</script>

<style scoped lang="less">
.bg {
    background: #fee26c;
    text-align: center;
    padding: 20px;
    .logo {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        background: #ffffff;
        margin-top: 20px;
        border-radius: 10px;
        line-height: 50px;
    }
    .card {
        border-radius: 10px;
        margin-top: 20px;
    }
    .login-way {
        .sms-way {
            background: #2c394e;
            border-radius: 25px;
            height: 50px;
            color: #ffffff;
            line-height: 50px;
            margin: 20px;
            text-align: center;
        }
    }
    .tip {
        position: fixed;
        bottom: 0;
        text-align: left;
        margin-bottom: 30px;
        p {
            font-size: 14px;
        }
    }
    .read-moudle {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 20px;
        .clause {
            font-size: 12px;
            font-family: SourceHanSansCN, SourceHanSansCN-Regular;
            font-weight: 400;
            text-align: left;
            line-height: 12px;
            margin-left: 10px;
        }
    }
    .login-way {
        .sms-way {
            background: #2c394e;
            border-radius: 25px;
            height: 50px;
            color: #ffffff;
            line-height: 50px;
            margin: 20px;
            text-align: center;
        }
    }
}
</style>
